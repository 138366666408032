App.modules.menu = (() => {

    let menu, burgers, competitions, fleches, formulaireRecherche;

    return {
        start() {
            if (menu = document.querySelector('[data-zone="menu-slide"]')) {
                competitions = menu.querySelector('[data-zone="competitions"]');
                fleches = competitions.querySelectorAll('div:last-of-type button');
                if (burgers = document.querySelectorAll('[data-action="afficher-menu-slide"]')) {
                    this.activerMenuSlide();
                    this.activerFlechesSlide();
                }
                if (document.location.hash == '#menu') {
                    this.ouvrirMenuSlide();
                }

            }
        },
        activerMenuSlide() {
            burgers.forEach(burger => burger.addEventListener('click', this.ouvrirMenuSlide));
            menu.querySelector('header [data-action="fermer-menu-slide"]').addEventListener('click', this.fermerMenuSlide)
            menu.querySelector('footer [data-action="fermer-menu-slide"]').addEventListener('click', this.fermerMenuSlide)
            document.addEventListener('key-Escape', this.fermerMenuSlide);
        },
        ouvrirMenuSlide() {
            document.body.dataset.menuSlide = 'ouvert';
            // menu.scrollIntoView()
            menu.style.top = px(App.modules.scroll.defilement.top)
        }, fermerMenuSlide() {
            document.body.dataset.menuSlide = 'ferme';
        },
        activerFlechesSlide() {
            fleches.forEach(fleche => fleche.addEventListener('click', (e) => {
                let width = competitions.querySelector('li').offsetWidth;
                if(fleche.dataset.action == 'left') {
                    width *= -1;
                }
                competitions.querySelector('ul').scrollBy(width,0)
            }));
        }
    }
})();