App.modules.actions = (() => {

    /**
     * Activer le fonctionnement boutons data-action="afficher-masquer" :
     * Ils afficheront l'element correspondant au selecteur stocké dans data-cible.
     * Une fois cliqués, ces boutons sont masqués s'ils ont l'attribut data-masquer="true"
     */
    function activerAfficherMasquer() {

        document.querySelectorAll('[data-action="afficher-masquer"]').forEach(element => {

            element.addEventListener('click', (e) => {
                let cible = e.target.dataset.cible;
                let cibles = document.querySelectorAll(cible);
                if (cibles) {
                    cibles.forEach(cible => {
                        if (cible.dataset.visibility == 'hidden') {
                            delete cible.dataset.visibility;
                        } else {
                            cible.dataset.visibility = 'hidden'
                        }
                    })
                    if (e.target.dataset.masquer) {
                        e.target.dataset.visibility = 'hidden';
                    }
                }
            });
        });
    }
    return {
        start() {
            activerAfficherMasquer()
        }
    }
})();