document.documentElement.classList.remove('no-js');

/*function detectBrowser() { 
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'opera';
    } else if(navigator.userAgent.indexOf("Chrome") != -1 ) {
        return 'chrome';
    } else if(navigator.userAgent.indexOf("Safari") != -1) {
        let version = browser_version();
        return 'safari';
    } else if(navigator.userAgent.indexOf("Firefox") != -1 ){
        return 'firefox';
    } else if((navigator.userAgent.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) {
        return 'ie';
    } 
} */
(() => {
    let browser = get_browser_version();
    document.querySelector('html').dataset.browser = browser.name;
    document.querySelector('html').dataset.browserVersion = browser.version;
    document.querySelector('html').dataset.browserOld = browser.old ? 'true' : 'false';
})();

function get_browser_version() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    let ret = {
        name: (M[0] + '').toLowerCase(),
        version: M[1],
        old:false
    };

    if(ret.name == 'safari' && ret.version < 14) {
        ret.old=true;
    }
    if(ret.name == 'id') {
        ret.old=true;
    }

    return ret;
}