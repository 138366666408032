/**
 * Module associés au scroll de la fenetre
 */
App.modules.scroll = (() => {
    let defilement = {};
    let scrollingStarted = false;
    /**
     * Gestion de la fin de sroll et de l'event scroll-end
     */
    const scrollEnd = () => {
        App.modules.scroll.definirDefilement();
        delete document.body.dataset.scrolling;

        let delta = Math.abs(scrollingStarted - window.scrollY);
        scrollingStarted = false;
        if (delta > 200) {
            emettreEvenement('scroll-enought');
        }
        emettreEvenement('scroll-end');
    }

    return {
        defilement,
        start() {
            this.definirDefilement();


            window.addEventListener('scroll', (e) => {
                if (scrollingStarted === false) {
                    scrollingStarted = window.scrollY;
                    emettreEvenement('scroll-start');
                }
                this.definirDefilement();

                attendre('scroll-end', scrollEnd, 100);
            }, { passive: true });

        },
        // activerScrollFixed() {
        //     let elements = document.querySelectorAll('[data-scroll-fixed="true"]:not([data-index])');
        //     if (elements.length) {
        //         elements.forEach((element, index) => {
        //             element.dataset.index = index;
        //             let offset = element.getBoundingClientRect();
        //             element.dataset.top = offset.top;
        //             element.dataset.left = offset.left;
        //             element.dataset.width = offset.width;
        //             element.dataset.height = offset.height;
        //             ['scroll', 'scroll-end'].forEach(event => document.addEventListener(event, (e) => {
        //                 // if ((isBreakpoint(element.dataset.etapes.split(',')) || isBreakpoint(element.dataset.etape)) && this.defilement?.top > element.dataset.top) {
        //                 if (this.defilement?.top > element.dataset.top) {
        //                     if (!element.dataset.fixed) {
        //                         element.dataset.fixed = true;

        //                         element.dataset.top = offset.top;
        //                         element.dataset.left = offset.left;
        //                         element.dataset.width = element.dataset.scrollWidth || offset.width;
        //                         element.dataset.height = offset.height;

        //                         element.style = `width:${px(element.dataset.width)};height:${px(element.dataset.height)};left:${px(element.dataset.left)};`;
        //                         let masque = this.createMasque(element);
        //                         element.insertAdjacentElement('afterend', masque)
        //                     }
        //                 }
                  
        //             }));
        //         })
        //     }
        // },
        createMasque(element) {
            let masque = document.createElement('div');
            masque.style.width = px(element.dataset.width);
            masque.style.height = px(element.dataset.height);
            masque.dataset.masque = true;
            masque.dataset.index = element.dataset.index;
            return masque;
        },

        // activerDivScrollables() {
        //     if (document.querySelector('[data-scroll]')) {
        //         document.querySelectorAll('[data-scroll]').forEach(item => {
        //             let scroll = item.dataset.scroll;

        //             item.addEventListener('wheel', (e) => {

        //                 if (e.deltaY != 0) {
        //                     if (this.checkOverflow(item)) {
        //                         e.preventDefault();
        //                         let delta = ((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1;
        //                         delta = delta * (-300);
        //                         if (scroll == 'horizontal') {
        //                             item.scrollLeft -= delta;
        //                         } else {
        //                             item.scrollTop -= delta;
        //                         }
        //                     }
        //                 }
        //             });
        //         })
        //     }
        // },

        // Determines if the passed element is overflowing its bounds,
        // either vertically or horizontally.
        // Will temporarily modify the "overflow" style to detect this
        // if necessary.
        checkOverflow(el) {
            var curOverflow = el.style.overflow;

            if (!curOverflow || curOverflow === "visible")
                el.style.overflow = "hidden";

            var isOverflowing = el.clientWidth < el.scrollWidth
                || el.clientHeight < el.scrollHeight;

            el.style.overflow = curOverflow;

            return isOverflowing;
        },
        /**
         * Mettre à jour les données de défilement
         */
        definirDefilement() {
            let d = {};

            // const wh = $(window).height();
            const wh = window.innerHeight
            // const top = $(window).scrollTop();
            const top = window.scrollY;

            const nav = document.querySelector('body>header.header');
            let oh = 0;
            if (nav) {
                oh = nav.offsetHeight;
            }
            let sens = false;

            if (this.defilement) {
                if (top > this.defilement.top) {
                    sens = 'down';
                } else
                    if (top < this.defilement.top) {
                        sens = 'up';
                    }
            }
            if (sens) {
                d.sens = sens;
                attendre('sens', () => {
                    document.body.dataset.sens = sens;
                }, 100);
            }
            document.body.dataset.scrolling = true;
            attendre('scrolled', () => {
                delete document.body.dataset.scrolling;
                if (top == 0) {
                    delete document.body.dataset.scrolled;
                } else {
                    document.body.dataset.scrolled = true;
                }
            }, 500);

            d.top = top;
            d.navHeight = oh + 'px';
            d.nav = top - oh;
            d.full = top + wh;
            d.center = top + (wh / 2);
            d.after = top + (wh * 1.5);

            this.defilement = d;
            return this.defilement;
        }
    }
})();




