App.modules.links = (() => {
    let liens;
    return {
        activerPreview() {
            liens.forEach(lien => {
                let meta = JSON.parse(lien.dataset.meta)
                lien.dataset.ready = true;
                let preview = document.createElement('span');
                preview.innerHTML = `
                <span>
                <button data-type="button" data-action="fermer">✖</button>
                <h3>${meta.titre}</h3>
                <small>${meta.domaine}</small>
                ${meta?.image?.url ? `<figure><img src="${meta.image.url}"></figure>`: ''}
                </span>
                `;

                lien.appendChild(preview);
            })
            
            document.querySelectorAll('a[data-meta] [data-action="fermer"]').forEach(button => button.addEventListener('click', (e) => {
                e.preventDefault();
                e.target.closest('a').dataset.closed = true
                return false;
            }))

        },
        start() {
            if (liens = document.querySelectorAll('a[data-meta]:not([data-ready])')) {
                this.activerPreview();
            }
        }
    }
})();