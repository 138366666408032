App.modules.forum = (() => {
    let forum, sections, messages, rediger, textarea, boutonValider, topicId;

    return {
        activerForumSections() {
            forum.querySelectorAll('[ data-action="voir-plus"]').forEach(bouton => bouton.addEventListener('click', (e) => {
                let section = e.target.closest('[data-zone="forum-section"]');
                delete section.dataset.voirPlus;
            }))
        },
        doForumRecherche(s) {
            if (!s) {
                let topics = forum.querySelectorAll('[data-zone="topics"] article[data-hidden]');
                for (let topic of topics) {
                    delete topic.dataset.hidden;
                }
                forum.querySelector('[data-zone="count"]').innerHTML = forum.querySelectorAll('[data-zone="topics"] article').length;

            } else {
                s = s.toLowerCase();
                let topics = forum.querySelectorAll('[data-zone="topics"] article');
                let cpt=0;
                for (let topic of topics) {
                    if (!topic.innerText.toLowerCase().includes(s)) {
                        topic.dataset.hidden = true;
                    } else {
                        cpt++;
                    }
                }
                forum.querySelector('[data-zone="count"]').innerHTML = cpt;
            }
        },
        activerForumRecherche() {
            let form = forum.querySelector('#recherche-forum');
            if (form) {
                let input = form.querySelector('input[type=text]');
                input.addEventListener('input', e => {
                    this.doForumRecherche(input.value)
                });
                form.addEventListener('submit', e => {
                    e.preventDefault();
                    this.doForumRecherche(input.value)
                    return false;
                });
            }
        },
        activerActionsMessages() {
            document.addEventListener('click', e => {
                if (e.target.closest('[data-action="annuler"]')) {
                    this.confirmerFermeture();
                    this.fermerEditerReponse();
                }
                if (e.target.closest('button[data-action]')) {
                    if (messages.dataset.rediger) return;
                    let messageId = e.target.closest('[data-id]').dataset.id;
                    let action = e.target.closest('button[data-action]').dataset.action;
                    console.log(action);
                    if (action == 'repondre') {
                        emettreEvenement('rediger-message-forum', { topicId, messageId }, this);
                    } else if (action == 'effacer') {
                        if (confirm("Voulez-vous effacer ce message ?")) {
                            emettreEvenement('effacer-message-forum', { messageId, topicId }, this);
                        }
                    } else if (action == 'editer') {
                        emettreEvenement('editer-message-forum', { messageId, topicId }, this);
                    }
                }
            });


            ['focus', 'keyup', 'change'].forEach(evenement => textarea.addEventListener(evenement, e => {
                let lines = nblines(textarea.value);
                e.target.setAttribute('rows', Math.min(20, lines));
                setReglage('message-' + topicId, e.target.value)
                if (e.type != 'focus') {
                    textarea.dataset.modified = true;
                }
            }));

            rediger.querySelector('form').addEventListener("submit", e => this.validerFormulaireRedaction(e));
            document.addEventListener('key-Escape', () => {
                this.confirmerFermeture();
                this.fermerEditerReponse();
            })
            document.addEventListener('key-Ctrl-Enter', (e) => {
                let cible = e.data.event.target.closest('textarea');
                if (cible && cible.closest('[data-zone="messages"]')) {
                    this.validerFormulaireRedaction(e.data.event);
                    this.validerFormulaireEdition(e.data.event);
                }
            })
            App.modules.clavier.add(['Ctrl-Enter']);
        },
        ouvrirEditerReponse(id, txt) {
            let message = this.findMessage(id);
            message.scrollIntoView();
            let template = getTemplate('editer', { txt });
            message.querySelector(':scope > div').innerHTML += template;
            setFocus(message.querySelector('textarea'));
            message.querySelector('form').addEventListener("submit", e => this.validerFormulaireEdition(e));
        },
        validerFormulaireEdition(e = false) {
            e.preventDefault();
            let bouton = document.querySelector('[data-zone="editer"] button[type="submit"].btn')
            if (bouton) {
                bouton.dataset.loading = true;
            }
            let message = e.target.querySelector('textarea').value;
            let id = bouton.closest('[data-zone="reponse"]').dataset.id;
            emettreEvenement('valider-editer-message-forum', { message, id, topicId }, this);
        },
        validerFormulaireRedaction(e = false) {
            e.preventDefault();
            boutonValider.dataset.loading = true
            let message = textarea.value;
            emettreEvenement('valider-rediger-message-forum', { message, topicId }, this);
        },
        fermerEditerReponse(content = null) {
            let form = document.querySelector('[data-zone="editer"]');
            if (form) {
                if (content !== null) {
                    form.closest('div').querySelector(':scope > div').innerHTML = content;
                }
                form.remove();
            }
        },
        confirmerFermeture() {
            if (messages.dataset.rediger) {
                if (!textarea.value || !textarea.dataset.modified || confirm("Voulez-vous fermer la zone de saisie ? Votre brouillon sera perdu.")) {
                    this.fermerRedigerReponse();
                }
            }
        },
        fermerRedigerReponse() {
            if (messages.dataset.rediger) {
                textarea.value = '';
                setReglage('message-' + topicId, '');
                delete boutonValider.dataset.loading;
                delete messages.dataset.rediger;
            }
        },

        ouvrirRedigerReponse(txt = '') {
            messages.dataset.rediger = true;
            txt = txt || getReglage('message-' + topicId, '');
            textarea.value = txt;
            textarea.focus();
        },

        findMessage(id) {
            return messages.querySelector(`article[data-zone="reponse"][data-id="${id}"]`);
        },
        start() {
            if (forum = document.querySelector('[data-zone="forum"]')) {
                if (sections = document.querySelectorAll('[data-zone="forum-section"]')) {
                    this.activerForumSections();
                }
                this.activerForumRecherche();
                if (messages = forum.querySelector('[data-zone="messages"]')) {
                    topicId = messages.dataset.id;
                    rediger = messages.querySelector('[data-zone="rediger"]');
                    textarea = rediger.querySelector('textarea');
                    boutonValider = rediger.querySelector('form button[type="submit"].btn');
                    this.activerActionsMessages()
                }
            }
        }
    }
})();


