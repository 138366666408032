App.modules.live = (() => {
    let Live;


    return {
        finChargementLive() {
            delete document.querySelector('html').dataset.liveLoading;
        },
        chargementLive() {
            emettreEvenement('live-recharger', {}, this);
            document.querySelector('html').dataset.liveLoading = true;
        },
        activerActions() {

            Live.querySelector('[data-action="reload"]').addEventListener('click', () => { App.modules.live.chargementLive() });
            setInterval(() => { App.modules.live.chargementLive() }, 30000);
        },
        activerTabs() {
            window.addEventListener('hashchange', this.hashchange);
            this.hashchange();
            Live.querySelector('[data-action="afficher-menu"]').addEventListener('click', (e) => {
                let menu = Live.querySelector('[data-zone="menu"]');
                if (menu.dataset.menu != 'ouvert') {
                    menu.dataset.menu = 'ouvert';
                } else {
                    delete menu.dataset.menu;
                }
            })
        },
        hashchange() {
            let hash = document.location.hash.replace('#', '');
            let tab = document.querySelector(`[data-tab="${hash}"]`);
            if (tab) {

                document.querySelectorAll(`[data-zone="menu"] [data-selected], [data-tab][data-selected]`)?.forEach(element => {
                    delete element.dataset.selected;
                })
                tab.dataset.selected = true;
                document.querySelector(`[data-zone="menu"] a[href="#${hash}"]`).closest('li').dataset.selected = true;
            }
        },
        afficherTousLesMessages(_callback = false) {
            delete Live.querySelector('[data-zone="messages"]').dataset.limit;
            if (_callback) {
                _callback();
            }
        },
        activerLive() {
            if (document.location.hash) {
                App.modules.live.afficherTousLesMessages();
            }
            
            document.querySelectorAll('[data-zone="buteurs"] a, .tirs-au-but a').forEach(a => a.addEventListener('click', e => {
                if (Live.querySelector('[data-zone="messages"]').dataset.limit) {
                    e.preventDefault();
                    App.modules.live.afficherTousLesMessages(() => {
                        document.location.hash = a.getAttribute('href');
                    });

                }
            }))
            document.querySelector('[data-zone="live"] [data-action="afficher-tout"]').addEventListener('click', (e) => {
                e.target.dataset.loading = true;
                emettreEvenement('live-afficher-messages', {}, this);
            });
            // document.querySelectorAll('[data-action="noter"]').forEach(button => {
            //     button.addEventListener('click', (e) => {
            //         document.body.dataset.noter = true;
            //     })
            // });
        },

        start() {
            if (document.querySelector('[data-zone="messages"]')) {
                if (Live = document.querySelector('[data-zone="live"]')) {
                    this.activerLive();
                    if (Live.dataset.typeLive == 'match') {
                        this.activerTabs();
                        this.activerActions();
                    }
                }
            }
        }
    }
})();