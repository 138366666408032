App.modules.breves = (() => {
    let breves;

    return {
        fermerApercus() {
            document.querySelectorAll('[data-zone="apercu-breve"]')?.forEach(apercu => {
                delete apercu.dataset.etat;
                setTimeout(() => {
                    apercu.remove();
                }, 500)
            })
        },
        ouvrirApercu(id, url, content) {
            let breve = document.querySelector(`[data-zone="breves"] .article[data-id="${id}"]`);
            console.log(breve)
            if (breve) {
                let titre = breve.querySelector('h2').innerText;
                let apercu = document.querySelector(`[data-zone="apercu-breve"][data-id="${id}"]`);
                if (!apercu) {
                    simulerPageVue(url,titre);

                    let template = getTemplate('apercu-breve', { id, content, url });
                    console.log(template)
                    breve.insertAdjacentHTML('afterend', template);
                    apercu = document.querySelector(`[data-zone="apercu-breve"][data-id="${breve.dataset.id}"]`);
                    setTimeout(() => {
                        apercu.dataset.etat = "reduit";
                    }, 10);
                } else {
                    apercu.querySelector(':scope>div').innerHTML=content;
                }
            }
        },
        activerApercuBreves() {
            document.querySelectorAll('[data-zone="breves"] .article').forEach(breve => {
                breve.querySelector(':scope>a').addEventListener('click', (e) => {
                    e.preventDefault();
                    let url = breve.querySelector(':scope > a').href;
                    this.fermerApercus();
                    this.ouvrirApercu(breve.dataset.id, url, '<div data-loading="true"></div>');
                    emettreEvenement('charger-apercu-breve', { id: breve.dataset.id, url }, this);
                })
            })
        },
        start() {
            // breves = document.querySelector('[data-zone="breves"]');
            // if (breves) {
            //     this.activerApercuBreves();
            // }
        }
    }
})();