/**
 * Notifications : afficher et gérer les notifications
 * 
 * Les notifications sont déclinées en :
 *  - 4 couleurs différentes : success, error, warning et info
 *  - 2 positions : statiques et fixed (en bas à gauche dans une zone de notifications pouvant contenir plusieurs notifications empilées)
 *  
 */
App.modules.notifications = (() => {
    let zoneNotifs;
    let id = 0;
    return {
        /**
         * AJouter une notification dans la zone de notification
         * options = {
         *   type,          // type de notif (success, error, warning et info)
         *   titre,         // titre de la notification (facultatif)
         *   contenu,       // Le contenu à afficher
         *   transition,    // l'animation d'entrée et de sortie
         *   close,         // Affficher un bouton pour fermer la notif 
         *   autoclose     // la notif se fermera toute seule apres 5 secondes (si true) ou le nombre de secondes contenues dans autoclose
         * }
         * Une fois créée, la notif se voit affecter un identifiant numérique unique
         * @param {*} options 
         * @returns 
         */
        afficher(options) {
            let active = false;
            this.creerZoneNotifs();
            options.active = options?.active || true;
            if (options.active) {
                active = true;
                options.active = false;
            }
            options.type = options?.type || 'info';
            options.titre = options?.titre || '';
            options.contenu = options?.contenu || '';
            options.transition = options?.transition || 'fade';
            options.autoclose = options?.autoclose || false;

            if(!options.autoclose && 'close' in options) {
                options.close = options?.close;
            } else {
                options.close=true;
            }
            options.id = ++id;

            let template = getTemplate('notification', options);
            zoneNotifs.innerHTML = template + zoneNotifs.innerHTML;
            let notification = document.querySelector(`.notification[data-id="${options.id}"]`);
            if (notification) {
                if (active) {
                    setTimeout(`App.modules.notifications.activer(${options.id});`, 100);
                }
                if (options.autoclose) {
                    let delay = options.autoclose === true ? 5 : options.autoclose;
                    setTimeout(`App.modules.notifications.fermer(${options.id});`, delay * 1000);
                }
            }
            return notification;
        },
        /**
         * Activer une notification qui a été au préalable ajoutée dans a la zone de notification
         * @param {int} id l'id numérique de la notif
         */
        activer(id) {
            let notification = document.querySelector(`.notification[data-id="${id}"]`);
            if (notification) {
                notification.dataset.active = true;
            }
        },
        /**
         * Retirer du dom une notification données
         * @param {int} id l'id numérique de la notif
         */
        detruire(id) {
            let notification = document.querySelector(`.notification[data-id="${id}"]`);
            if (notification) {
                notification.remove();
            }
        },
        /**
         * Masquer, puis détruire une notification
         * @param {int} id l'id numérique de la notif
         */
        fermer(notification) {
            if (typeof notification == 'number') {
                notification = document.querySelector(`.notification[data-id="${notification}"]`);
            }
            if (notification) {
                notification.dataset.active = false;
                setTimeout(`App.modules.notifications.detruire(${notification.dataset.id});`, 500);
            }
        },
        /**
         * Activer la gestion des notifications
         */
        activerNotifications() {
            document.addEventListener('key-Escape', () => {
                let delay = 0;
                document.querySelectorAll('[data-zone="notifications"] .notification').forEach(notification => {
                    setTimeout(`App.modules.notifications.fermer(${notification.dataset.id});`, delay += 300);
                })
            });

            document.addEventListener('click', e => {
                // si on a cliqué sur une notification qui est "fermable"
                let notification = e.target.closest('.notification[data-close="true"]');
                if (notification) {
                    // si on n'a pas cliqué sur un lien dans cette notification
                    if (e.target.closest('a')) return;
                    // on ferme
                    this.fermer(notification)
                }
            });
        },
        /**
         * Créer la zone de notification pouvant contenir plusieurs notifications
         */
        creerZoneNotifs() {
            if (!zoneNotifs) {
                zoneNotifs = document.querySelector('[data-zone="notifications"]');
                if (!zoneNotifs) {
                    let div = document.createElement('div');
                    div.dataset.zone = 'notifications';
                    document.body.appendChild(div);
                }
                zoneNotifs = document.querySelector('[data-zone="notifications"]');
            }
        },
        /**
         * 
         * @param {Object} notification Stocker une notification dans la session pour l'afficher dans la page suivante
         */
        stocker(notification) {
            let notifications = getValeur('notifications', { default: [] });
            notifications.push(notification);
            setValeur('notifications', notifications);
        },
        activerNotificationsStockees() {
            let notifications = getValeur('notifications', { clear: true, default: [] });
            notifications.forEach(notification => {
                this.afficher(notification);
            })
        },
        start() {
            this.activerNotifications();
            this.activerNotificationsStockees();
        }
    }
})();