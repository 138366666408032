App.modules.debug = (() => {
    function setDebugFromHash() {
        debug = document.location.hash.includes('debug');
    }

    return {
        start() {
            return;            
            setDebugFromHash();
            document.addEventListener('hashchange', setDebugFromHash);
            if (!debug) {
                let url = document.location.href.split('#')[0] + "#debug";
                console.info('🧙🏻‍♂️ %cActivez le debug avec %cdebug=true %cou en activant le hash %c#debug\n' + url, "color:blue", "color:gray;font-family:monospace", "color:blue", "color:gray;font-family:monospace");
            }
        }
    }
})();
function log(message,data=null) {
    return;
    if (debug) {
        console.log(message);
        console.log(data);
        if(data!=null)  {
        }
    }
}
