App.modules.compte = (() => {

    return {
        removeConnected() {
            document.body.setAttribute('data-connection-checked', 'false')
            document.body.setAttribute('data-connected-admin', 'false')
            document.body.setAttribute('data-connected', 'false')
            document.body.setAttribute('data-connected-id', '')
            document.querySelectorAll('style#connected-style').forEach(style => style.remove);
        },
        setConnected(connectedId, options) {
            // document.body.setAttribute('data-connection-checked', options?.checked ? 'true' : 'false')
            document.body.setAttribute('data-connected-admin', options?.admin ? 'true' : 'false')
            document.body.setAttribute('data-connected', 'true')
            document.body.setAttribute('data-connected-id', connectedId)
            let style = document.createElement('style');
            style.id = 'connected-style';
            style.innerHTML = `
                [data-connected-id="${connectedId}"] [data-if-connected-id]:not([data-if-connected-id="${connectedId}"]){
                    display: none;
                }
            `;

            document.head.appendChild(style)
        },
        start() {

        }
    }
})();