App.modules.storiesSlider = (() => {

    let storiesSlider, currentStory, dialog;
    function setInstagram() {
        if (typeof instgrm != 'undefined') {
            instgrm.Embeds.process();
        } else {
            const script = document.createElement('script');
            script.setAttribute('defer', true)
            script.setAttribute('async', true)
            script.setAttribute('src', '//www.instagram.com/embed.js')
            document.head.appendChild(script)
        }
    }
    function setTiktok() {

        const script = document.createElement('script');
        script.setAttribute('defer', true)
        script.setAttribute('async', true)
        script.setAttribute('src', '//www.tiktok.com/embed.js')
        document.head.appendChild(script)

    }


    function closeStory() {
        dialog?.remove();
    }

    function trouverVideoPrincipale(container, cells) {
        const centerOfWindow = window.innerWidth / 2;
        let closestCell = null;
        let closestDistance = Infinity;

        cells.forEach(cell => {
            const cellRect = cell.getBoundingClientRect();
            const cellCenter = cellRect.left + cellRect.width / 2;
            const distanceToCenter = Math.abs(centerOfWindow - cellCenter);

            if (distanceToCenter < closestDistance) {
                closestDistance = distanceToCenter;
                closestCell = cell;
            }
        });

        return closestCell;
    }

    function handleScroll() {



        const scrollContainer = dialog.querySelector('section');
        const articles = scrollContainer.querySelectorAll('article');

        scrollContainer.dataset.dragging = false;
        let startX;
        let scrollLeft;
        let dragDistance = 0;

        // Mouse down event to start dragging
        scrollContainer.addEventListener('mousedown', (e) => {
            scrollContainer.dataset.dragging = true;
            startX = e.pageX - scrollContainer.offsetLeft;
            scrollLeft = scrollContainer.scrollLeft;
            dragDistance = 0; // Reset drag distance on each mouse down
        });

        // Mouse leave event to stop dragging
        scrollContainer.addEventListener('mouseleave', () => {
            scrollContainer.dataset.dragging = false;
        });

        // Mouse up event to stop dragging and check for click
        scrollContainer.addEventListener('mouseup', (e) => {
            scrollContainer.dataset.dragging = false;
            if (dragDistance < 5) {
                // Trigger a click only if drag distance is minimal (below threshold)
                const targetElement = e.target.closest('.clickable'); // Adjust if cells have specific clickable elements
                if (targetElement) targetElement.click();
            }
        });

        // Mouse move event to handle dragging and scrolling
        scrollContainer.addEventListener('mousemove', (e) => {
            if (scrollContainer.dataset.dragging != 'true') return;
            e.preventDefault();
            const x = e.pageX - scrollContainer.offsetLeft;
            const walk = (x - startX) * 1.5; // Adjust the scroll speed here if needed
            scrollContainer.scrollLeft = scrollLeft - walk;
            dragDistance += Math.abs(walk); // Track the total distance dragged
            const principale = trouverVideoPrincipale(scrollContainer, articles);
            setVideoPrincipale(scrollContainer, principale, 0);
        });
        scrollContainer.addEventListener('wheel', function (e) {
            if (scrollContainer.dataset.dragging == 'true') return;
            attendre(() => {

                e.preventDefault();
                const principale = scrollContainer.querySelector('.principale');
                if (!principale) return;
                let slug = false;
                if (e.deltaY < 0) {
                    slug = principale.previousElementSibling?.dataset.slug
                } else if (e.deltaY > 0) {
                    slug = principale.nextElementSibling?.dataset.slug
                }
                if (!slug) return;
                scrollTo(slug)
            })
        });
        let pointer = false;
        scrollContainer.addEventListener('scroll', e => {
            if (scrollContainer.dataset.dragging == 'true') return;

            if (pointer) {
                clearTimeout(pointer)
            }
            pointer = setTimeout(() => {
                console.log('scroll stories')
                const articleCourant = trouverVideoPrincipale(scrollContainer, articles)
                if (!articleCourant) return;
                articles.forEach(article => {
                    if (article == articleCourant) return;
                    const video = article.querySelector('video');
                    if (!video) return;
                    if (video.paused) return;
                    video.pause();
                })
                setVideoPrincipale(scrollContainer, articleCourant)
                setTimeout(() => {
                    const post_url = articleCourant.dataset.url;
                    const video = articleCourant.querySelector('video');
                    video.muted = dialog.dataset.muted == "true"
                    video.play();
                    emettreEvenement('story-video-play', { post_url });
                }, 500)
            }, 300);
        });
    }

    function setVideoPrincipale(scrollContainer, articleCourant, delay = 50) {
        const prev = scrollContainer.querySelector('article.principale');
        if (prev && prev != articleCourant) {
            setTimeout(() => {
                prev.classList.remove('principale');
            }, delay)
        }
        articleCourant.classList.add('principale')
    }
    function openStory(article) {
        if (!article) return;
        dialog = document.querySelector(`#stories-slider-dialog`)
        if (!dialog) {
            dialog = document.createElement('dialog');
            dialog.dataset.muted = true;
            dialog.innerHTML = `<section><div><article></article></div></section>`;
            dialog.id = `stories-slider-dialog`;

            storiesSlider.querySelectorAll('article[data-slug]').forEach(item => {
                const slug = item.dataset.slug;
                const mp4 = item.dataset.mp4;
                const webm = item.dataset.webm;
                const post_url = item.querySelector('a').href;
                const poster = item.querySelector('img:not(.logo)').src;
                const logo = item.querySelector('img.logo').src;
                const code = `
                <a href="${post_url}" target="_blank"></a>
                <img src="${logo}" class="logo">
                <video preload="none" playsinline loop="true" poster="${poster}">
                <source src="${mp4}" type="video/mp4">
                <source src="${webm}" type="video/webm">
            </video>`
                const video = document.createElement('article')
                video.dataset.url = post_url
                video.dataset.slug = slug
                video.innerHTML = code;
                video.addEventListener('click', e => {
                    if (e.target.closest('.principale')) {
                        if (e.target.closest('a')) {
                            return;
                        }
                        dialog.dataset.muted = !(dialog.dataset.muted == 'true');
                        dialog.querySelectorAll('video').forEach(video => {
                            if (video.paused) return;
                            video.muted = dialog.dataset.muted == 'true'
                        })
                    } else {
                        const slug = video.closest('article').dataset.slug
                        scrollTo(slug)
                    }
                })
                const player = video.querySelector('video')
                const events = ['waiting',
                    'playing',
                    'canplay',
                    'pause'];
                events.forEach(eventName => player.addEventListener(eventName, () => {
                    player.dataset.status = eventName;
                }))


                dialog.querySelector('div').append(video)
            })
            dialog.querySelector('div').append(document.createElement('article'))

            document.body.append(dialog)

            dialog.addEventListener('click', e => {
                const button = e.target.closest('button');
                if (button) {
                    const action = button.dataset.action;
                    if (!action) return;
                    e.preventDefault();

                    if (action == 'next') {
                        openStory(currentStory.nextElementSibling)
                    } else {
                        openStory(currentStory.previousElementSibling)
                    }
                }
                if (e.target == dialog) {
                    closeStory()
                }
            })
            handleScroll()
        }
        dialog.showModal();
        const slug = article.dataset.slug
        setTimeout(() => scrollTo(slug), 500)
        return true;
    }
    function scrollTo(slug) {
        const targetCell = dialog.querySelector(`[data-slug="${slug}"]`);
        if (targetCell) {
            const container = dialog.querySelector('section');
            const containerRect = container.getBoundingClientRect();
            const targetRect = targetCell.getBoundingClientRect();
            const containerScrollLeft = container.scrollLeft;

            // Calculate the new scroll position to center the target cell
            const scrollToPosition = containerScrollLeft + (targetRect.left - containerRect.left)
                - (containerRect.width / 2) + (targetRect.width / 2);

            // Smoothly scroll the container to the calculated position
            container.scrollTo({ left: scrollToPosition, behavior: 'smooth' });
        }
    }


    // function openStory(article) {
    //     if (!article) return;
    //     closeStory();
    //     const video_url = article.dataset.video;
    //     const code = `<video loop="true">
    //         <source src="${video_url}" type="video/mp4">
    //         <source src="https://tools.sopress.dev/convertVideo/?v=${video_url}&to=webm" type="video/webm">
    //     </video>`
    //     currentStory = article;
    //     dialog = document.createElement('dialog');
    //     dialog.innerHTML = `<div>${code}</div><nav><button data-action="prev"></button><button data-action="next"></button></nav>`;

    //     storiesSlider.append(dialog)
    //     dialogStart();
    //     dialog.showModal();
    //     dialog.querySelector('video').play()
    //     emettreEvenement('video-play', { video_url });

    //     dialogEnd()
    //     return true;
    // }
    function dialogStart() {
        dialog.showModal();
        dialog.querySelector('div').dataset.loading = true
    }

    function dialogEnd() {
        setTimeout(() => {
            delete dialog.querySelector('div').dataset.loading;
        }, 1000)
    }
    return {

        start() {


            storiesSlider = document.querySelector('.stories-slider');
            if (!storiesSlider) return;

            // if (!document.location.hash.includes('stories')) {
            //     document.querySelector('.stories-slider-wrapper').remove();
            //     return;
            // }

            storiesSlider.addEventListener('click', e => {
                const article = e.target.closest('article');

                if (article) {
                    if (openStory(article)) {
                        return e.preventDefault();
                    }
                }
            });


        }
    }
})();

