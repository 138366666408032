
App.modules.jaime = (() => {
    let Jaime;
    return {
        construireChoix(emojis) {
            console.log(emojis);
            let choix = document.querySelector('.jaime__choix');
            choix.querySelectorAll('[data-action="jaime"]').forEach(element => element.closest('li').remove());
            for (let emoji of emojis.reverse()) {
                console.log(emoji);
                let li = document.createElement('li');
                li.innerHTML = `<button data-action="jaime">${emoji}</button>`;
                choix.prepend(li);
            }
        },
        construireAvis(jaimes) {

            let html = [];
            let restes = [];
            let nb_restes = 0;
            for (let emoji in jaimes) {
                if (html.length < 3) {
                    html.push(`<li><abbr><button data-action="jaime">${emoji}</button></abbr><span><span>${reduireValeur(jaimes[emoji])}</span></span></li>`);
                } else {
                    nb_restes += jaimes[emoji];
                    restes.push(emoji)
                }
            }
            if (restes.length) {
                html.push(`<li><abbr><span>${restes.reverse().join('</span><span>')}</span></abbr><span><span>${reduireValeur(nb_restes)}</span></span></li>`);
            }
            Jaime.querySelector('.jaime__avis').innerHTML = html.join('');
        },
        activerChoix() {
            Jaime.querySelector('[type="emoji"]').addEventListener('input', (e) => {
                if (isEmoji(e.target.value) && compterEmoji(e.target.value) == 1) {
                    delete document.querySelector('.jaime__choix').dataset.autre;
                    document.querySelector('[data-action=autre]').dataset.loading = true;
                    emettreEvenement('choix-jaime', { emoji: e.target.value }, this);
                }
                e.target.value = '';
            });
        },
        setEvents() {

            document.querySelector('[data-action="jaimes"]')?.addEventListener('click', (e) => {
                console.log(e.target.closest('button'))
                let bouton = e.target.closest('button');
                bouton.dataset.loading = true;
                emettreEvenement('chargement-jaime', {}, this);
                document.addEventListener('popup-avis', (e) => {
                    bouton.closest('[data-zone="likes"]').appendChild(Jaime);
                    Jaime.dataset.loaded = true;
                    delete bouton.dataset.loading;
                })
            })
            /**
             * update-jaime : construire la liste des choix et la liste des avis déjà envoyés
             */
            document.addEventListener('update-jaime', (e) => {
                this.construireChoix(e.data.emojis);
                this.construireAvis(e.data.jaimes)

                delete Jaime.dataset.chargement;
                Jaime.dataset.loaded = true;
                Jaime.querySelectorAll('[data-loading]').forEach(element => delete element.dataset.loading);
                document.querySelector('[data-zone="likes"] span').innerHTML = e.data.likes;
                delete document.querySelector('.jaime__choix').dataset.autre;
            })


            Jaime.addEventListener('click', (e) => {
                console.log(e.target)
                let button = e.target.closest('button[data-action]');
                if (button) {
                    if (button.dataset.action == 'autre') {
                        document.querySelector('.jaime__choix').dataset.autre = true;
                        document.querySelector('[type="emoji"]').focus();
                    }
                    if (button.dataset.action == 'jaime') {
                        let emoji = button.innerHTML;
                        button.dataset.loading = true;

                        emettreEvenement('choix-jaime', { emoji }, this);
                    }
                    if (button.dataset.action == 'fermer') {
                        if (button.closest('[data-zone="likes"]')) {
                            document.querySelector('#jaime').appendChild(Jaime);
                        } else {
                            button.closest('.jaime').dataset.closed = true;
                        }
                    }
                    if (button.dataset.action == 'ouvrir') {
                        delete button.closest('.jaime').dataset.closed;
                    }
                }

                if (e.target.closest('.jaime__avis')) {
                    delete e.target.closest('.jaime').dataset.closed;
                }
            })
        },
        activerJaime() {
            Jaime = document.querySelector('.jaime');
            if (Jaime) {
                

                this.activerChoix();

                this.setEvents();
            }
        },
        start() {
            // this.activerJaime();
        }
    }
})();