/**
 * Converts time phrases to milliseconds.
 * 
 * @param {string} str - Time phrase to be converted.
 * @returns {number} Time in milliseconds.
 *
 * @example
 * // Converts '1 hour' to milliseconds
 * const milliseconds = timeStringToMs('1 hour');
 */
function timeStringToMs(str) {
    const timeUnits = {
        second: 1000,
        minute: 60 * 1000,
        hour: 60 * 60 * 1000,
        day: 24 * 60 * 60 * 1000,
    };

    const [value, unit] = str.split(' ');

    // Consider both singular and plural forms
    const normalizedUnit = unit.endsWith('s') ? unit.slice(0, -1) : unit;

    return value * timeUnits[normalizedUnit];
}


/**
 * Determines whether it's time to do a task again, based on the number of times the task should be done per day.
 * 
 * @param {string} taskName - The name of the task.
 * @param {number} repeatsPerDay - The number of times the task should be done per day.
 * @returns {boolean} True if the task has never been done or if it's time to do it again, false otherwise.
 * 
 * @example
 * // Check if it's time to do 'task1' again, which should be done 2 times per day
 * const isTimeToDoAgain = isTimeToDoTask('task1', 2);
 */
function isTimeToDoTask(taskName, repeatsPerDay) {
    const key = taskName + repeatsPerDay;
    const lastDone = localStorageGet(key);
    const now = new Date().getTime().toString();


    // If the task was never done, return true
    if (!lastDone) {
        localStorageSet(key, now);
        return true;
    }

    // Calculate the minimum time between repetitions in milliseconds
    const msBetweenRepeats = 24 * 60 * 60 * 1000 / repeatsPerDay;

    // If the task was done and enough time has passed, return true
    if (now - lastDone >= msBetweenRepeats) {
        localStorageSet(key, now);
        // Update the last done time
        return true;
    }

    // If the task was done but not enough time has passed, return false
    return false;
}
