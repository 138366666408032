App.modules.notes = (() => {
    let Notes;
    let Clavier;
    let Courant;
    return {
        setEtat(courant = false) {
            App.modules.clavier.clearPrevent();

            document.body.dataset.noter = false;
            Notes.querySelectorAll('[data-slug][data-etat="en-cours"]').forEach(item => {
                delete item.dataset.etat
            });
            if (courant && courant.dataset?.zone == 'note') {
                App.modules.clavier.prevent(['PageUp', 'PageDown', 'ArrowUp', 'ArrowDown']);
                console.log(courant)
                document.body.dataset.noter = true;
                courant.dataset.etat = "en-cours";
                let nom = courant.querySelector('h3').innerHTML;
                if (Clavier.querySelector('[data-loading="true"]')) {
                    delete Clavier.querySelector('[data-loading="true"]').dataset.loading;
                }
                Clavier.querySelector('h3 span').innerHTML = ' pour ' + nom;
                let photo = courant.querySelector('img').src;
                if (photo) {
                    Clavier.querySelector('.boutons img').src = photo;
                }
                document.querySelector('*:focus')?.blur();

                attendre(() => {
                    // Clavier.querySelector('[data-action="choix-note"]').focus();
                    centrer(courant);
                })
            }
        },
        setNote(slug, note) {
            let element = Notes.querySelector(`[data-slug="${slug}"]`);
            console.log(element)
            if (element) {
                element.dataset.note = note;
                element.querySelector('[data-zone="ma-note"] var').innerText = note;

            }
        },
        activerNotes() {

            Clavier.querySelector('[data-action="fermer"]').addEventListener('click', () => {
                document.body.dataset.noter = false;
                this.setEtat()
            });

            Clavier.querySelectorAll('[data-action="choix-note"]').forEach(button => {
                button.addEventListener('click', (e) => {
                    let note = Number(e.target.innerHTML);
                    console.log(e.target)
                    Courant = this.getCurrentNote();
                    let slug = Courant.dataset.slug;
                    let blurred = Notes.querySelector(`[data-zone="note"][data-slug="${slug}"]`).querySelector('.blurred');
                    if (blurred) {
                        blurred.classList.remove('blurred');
                    }
                    e.target.dataset.loading = true;
                    setTimeout(() => {
                        emettreEvenement('noter', {
                            note,
                            slug
                        }, this);
                    }, 2000);
                });

            });
            Notes.querySelectorAll('[data-action="annuler"]').forEach(button => {
                button.addEventListener('click', (e) => {
                    document.body.dataset.noter = false;
                    this.setEtat()
                });
            });
            Notes.querySelectorAll('[data-action="noter"]').forEach(button => {
                button.addEventListener('click', (e) => {
                    document.body.dataset.noter = true;
                    this.setEtat(e.target.closest('[data-slug]'))
                })
            });
        },
        findNextZoneNotes(notes) {
            ret = false;
            while (!ret && notes.nextElementSibling) {
                console.log(notes)
                notes = notes.nextElementSibling;
                if (notes.dataset.zone == 'notes') {
                    ret = notes;
                }
            }
            return ret;
        },
        getNextNote() {
            if (Courant = this.getCurrentNote()) {
                let next = Courant.nextElementSibling;

                if (next && !next.dataset.slug) {
                    next = false;
                }

                if (!next) {
                    let zone = Courant.closest('[data-zone="notes"]');
                    let nextNotes = this.findNextZoneNotes(zone);

                    console.log(zone, next);
                    if (nextNotes) {
                        next = nextNotes.querySelector('[data-zone="note"]');
                    }
                }
                if (!next) {
                    let zone = Courant.closest('[data-zone="notes"]').nextElementSibling;
                    if (zone) {
                        next = zone.querySelector('[data-slug]');
                    }
                }
                return next;
            }
        },
        getPrevNote() {
            if (Courant = this.getCurrentNote()) {
                let prev = Courant.previousElementSibling;
                if (prev && !prev.dataset.slug) {
                    prev = false;
                }
                if (!prev) {
                    let zone = Courant.closest('[data-zone="notes"]').previousElementSibling;
                    if (zone) {
                        prev = zone.querySelector('[data-slug]:last-of-type');
                    }
                }
                return prev;
            }
        }

        /**
         * Obtenir l'élément HTML de la note courant
         * @returns {object} Note courante
         */
        ,
        getCurrentNote() {
            if (document.body.dataset.noter) {
                return Notes.querySelector('[data-slug][data-etat="en-cours"]');
            }
        }

        /**
         * On peut utiliser les flèches pour choisir la note à donner sur le clavier
         * On peut le fermer avec la touche Echap
         */
        ,
        activerClavier() {
            App.modules.clavier.add(['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageUp', 'PageDown']);

            document.addEventListener('key-Escape', () => {
                this.setEtat();
            });

            ['ArrowDown', 'ArrowUp'].forEach(touche => document.addEventListener(`touche-${touche}`, (e) => {
                let index;
                let touche = e.data.touche;
                if (touche == 'ArrowUp') {
                    index = 'previousElementSibling';
                } else {
                    index = 'nextElementSibling';
                };
                let cur = this.toucheCourante(touche == 'ArrowUp');
                if (cur) {
                    let next = (() => {
                        for (let i = 0; i < 6; i++) {
                            console.log(cur)
                            if (!cur) {
                                return;
                            }
                            cur = cur[index];
                        }
                        return cur;
                    })();

                    console.log(next);
                    if (next && next.dataset.action == "choix-note") {
                        next.focus()
                    }
                }
            }));

            document.addEventListener('key-ArrowLeft', () => {
                let cur = this.toucheCourante(true)
                if (cur) {
                    let prev = cur.previousElementSibling;
                    if (prev && prev.dataset.action == "choix-note") {
                        prev.focus()
                    }
                }
            })
            document.addEventListener('key-ArrowRight', () => {
                let cur = this.toucheCourante()
                if (cur) {
                    let next = cur.nextElementSibling;
                    if (next && next.dataset.action == "choix-note") {
                        next.focus()
                    }
                }
            })
        }
        /**
         * On peut passer d'une note à l'autre avec les flèches haut et bas
         */
        ,
        activerNavigationNotes() {
            document.addEventListener('key-PageDown', (e) => {
                console.log(e)
                let next;
                if (next = this.getNextNote()) {
                    e.preventDefault()
                    this.setEtat(next);
                }
            })
            document.addEventListener('key-PageUp', (e) => {
                console.log(e)
                let prev;
                if (prev = this.getPrevNote()) {
                    e.preventDefault()
                    this.setEtat(prev);
                }
            })

        }
        /**
         * Retourne la touche courante (qui a le focus) du clavier. Si pas de focus, on prend la touche correspondante au selecteur passé en paremetre
         * @returns Touche courante
         */
        ,
        toucheCourante(last = false) {
            if (this.getCurrentNote()) {
                let cur = Clavier.querySelector('[data-action="choix-note"]:focus');
                if (!cur) {
                    cur = Clavier.querySelector('[data-action="choix-note"]' + (last ? ':last-of-type' : ''));
                }
                return cur;
            }
        },
        flouterNotes() {

            let consignes = `Les notes de la rédaction sont masquées afin de ne pas influer sur vos décisions. Chacune de vos notations sur un joueur révèlera la note qui lui a été donnée par la rédaction. <a href="#reveler-notes">Vous pouvez aussi révéler toutes les notes en cliquant ici</a>.`;
            let p = document.createElement('p');
            p.innerHTML = consignes;
            p.classList.add('consignes');
            let node = document.querySelector('[data-zone="notes"]');
            node.parentNode.insertBefore(p, node);
            Notes.querySelector('[href="#reveler-notes"]').addEventListener('click', (e) => {
                e.preventDefault();
                Notes.querySelectorAll('.blurred').forEach(item => item.classList.remove('blurred'));
                setTimeout(() => e.target.closest('.consignes').remove(), 1000);
                return false;
            });
            Notes.querySelectorAll('[data-zone="notation"] > div:first-of-type > span:last-of-type > span> span').forEach(note => {
                note.classList.add('blurred');
            });
            let nb_clicks = 0;
            Notes.querySelectorAll('.blurred').forEach(note => {
                note.addEventListener('click', (e) => {
                    // if (nb_clicks == 1) {
                    //     if (confirm('Voulez-vous révéler toutes les notes ?\nCliquez sur "annuler" pour ne révéler que la note que vous venez de cliquer et garder le suspens pour le reste.')) {
                    //         Notes.querySelectorAll('.blurred').forEach(item => item.classList.remove('blurred'));
                    //     }
                    // }
                    nb_clicks++;
                    note.classList.remove('blurred');
                });
            });
        },
        start() {
            if (document.querySelector('[data-action="noter"]')) {
                Notes = document.querySelector('[data-zone="content-notes"]')
                Clavier = document.querySelector('.notes-clavier');
                this.activerNotes();

                this.activerNavigationNotes();
                this.activerClavier();
                if (Notes.dataset.flouter) {
                    this.flouterNotes();
                }
            }
        }
    }
})();