/**
 * Stores a key-value pair in localStorage that expires after a specified time.
 *
 * @param {string} key - The name of the key to store in localStorage.
 * @param {string} value - The value to be stored.
 * @param {(number|string)} [ttl=3600000] - Time to live: the time in milliseconds after which the value expires. Defaults to 1 hour.
 *                                        - Can be a string such as '1 hour', '2 minutes', '30 seconds', etc.
 *
 * @example
 * // Store 'value' at 'key', which expires after 1 hour (default)
 * localStorageSet('key', 'value');
 *
 * @example
 * // Store 'value' at 'key', which expires after 5 minutes
 * localStorageSet('key', 'value', '5 minutes');
 */
function localStorageSet(key, value, ttl = '1 hour') {
    const now = new Date();
    const expiry = (typeof ttl === 'string')
        ? now.getTime() + timeStringToMs(ttl)
        : now.getTime() + ttl;
    
    const item = {
        value: value,
        expiry: expiry,
    };

    localStorage.setItem(key, JSON.stringify(item));
}

/**
 * Retrieves a value from localStorage only if it has not yet expired.
 *
 * @param {string} key - The name of the key in localStorage to retrieve.
 * @returns {(string|null)} The retrieved value, or null if the value does not exist or has expired.
 *
 * @example
 * // Retrieve 'key' from localStorage
 * const value = localStorageGet('key');
 * console.log(value); // Logs: 'value' or null if 'value' has expired or doesn't exist
 */
function localStorageGet(key) {
    const itemStr = localStorage.getItem(key);

    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
    }

    return item.value;
}