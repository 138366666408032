App.modules.top = (() => {
    let slideCourant;
    // let top;
    // let numeros;
    // let numerosScroll;
    let slides;
    // let slider;
    let sens;

    return {
        slideCourant,
        /**
         * désactiver le scroll par hash pour eviter les sauts
         */
        disableAnchorScroll() {
            slides.forEach(slide => {
                slide.dataset.id = slide.id;
                slide.removeAttribute('id');
            })
            window.addEventListener('hashchange', () => {
                this.hashchange()
            })


            setTimeout(() => {
                let hash = document.location.hash.replace('#', '');
                if (!hash) {
                    document.location.hash = '#' + this.slider().querySelector('[data-zone="slide"]').dataset.id;
                } else {
                    this.hashchange();
                }
            }, 1000);
        },

        /**
         * Si le hash change, on doit défiler vers le bon slide
         */
        hashchange() {
            let hash = document.location.hash.replace('#', '');
            console.log(hash);
            if (hash) {

                let slide = this.slider().querySelector(`[data-id="${hash}"]`);
                // console.log(slide)
                let l = slide.offsetLeft - this.slider().offsetLeft
                this.slider().scrollLeft = l;
                this.trouverSlideCourant();
                this.definirNumero();
            }
        },
        /**
         * On peut utiliser le clavier pour naviguer
         */
        activerNavigationClavier() {
            App.modules.clavier.add(['ArrowLeft', 'ArrowRight', 'Home', 'End']);
            App.modules.clavier.prevent(['Home', 'End']);

            document.addEventListener('key-Home', () => {
                this.numeros().querySelector('[data-zone="numero"]').click();
            });
            document.addEventListener('key-End', () => {
                this.numeros().querySelector('[data-zone="numero"]:last-of-type').click();
            });
            document.addEventListener('key-ArrowLeft', () => {
                this.slideCourant.querySelector('[data-action="prev"]').click()
            });
            document.addEventListener('key-ArrowRight', () => {
                this.slideCourant.querySelector('[data-action="next"]').click()
            });

        },

        /**
         * Actions effectuées quand on scrolle la liste des numéris
         */
        activerScrollNumeros() {
            this.numerosScroll().addEventListener('scroll', (e) => {
                this.definirNumero();
            }, { passive: true });
        },

        definirNumero() {
            let sh = this.numerosScroll().scrollHeight;
            let h = this.numerosScroll().offsetHeight;
            let position = this.numerosScroll().scrollTop;

            let debut = false;
            if (position < 10) {
                debut = true;
            }

            let fin = false;
            if (sh - (h + position) < 10) {
                fin = true;
            }
            // console.log(sh, h, position, h + position);
            this.numeros().dataset.debut = debut
            this.numeros().dataset.fin = fin
        },

        /**
         * Définir le numéro courant dans la liste des numéros à gauche
         * @param {int} numero 
         */
        setNumero(numero) {
            this.top().querySelectorAll(`[data-zone="numero"]`).forEach(item => delete item.dataset.selected);
            let element = this.top().querySelector(`[data-zone="numero"][data-numero="${numero}"]`);
            element.dataset.selected = true;
            console.log('numero', element)
            // element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            attendre(() => {
                document.location.hash = '#slide-' + numero;
            });
            console.log('numerosScroll', this.numerosScroll())

            let t;
            if (sens == 'horizontal') {
                t = element.offsetLeft - this.numerosScroll().offsetLeft - element.offsetWidth
                this.numerosScroll().scrollLeft = t;
            } else {
                t = element.offsetTop - this.numerosScroll().offsetTop - element.offsetHeight
                this.numerosScroll().scrollTop = t;
            }

            this.taillesNumeros();
            let slide = document.querySelector(`[data-zone="slide"][data-id="slide-${numero}"]`)
            this.top().querySelector('[data-zone="slider"]').style.height = `calc(${slide.querySelector(':scope > div').offsetHeight}px + 30vh)`;
        },

        /** change la taille des numéros autour du numéro selectionné */
        taillesNumeros(decalage = 4) {
            let tab = this.numeros().querySelectorAll('[data-zone="numero"]');
            if (tab) {
                let rang;
                let index = 0;
                for (let item of tab) {
                    delete item.dataset.niveau;
                    if (item.dataset.selected) {
                        rang = index;
                        console.log(index);
                        break;
                    }
                    index++;
                };
                niveau = 1;
                tab.forEach((item, index) => {
                    let delta;
                    if (index < rang) {
                        delta = rang - index;
                    } else if (index > rang) {
                        delta = index - rang;
                    } else {
                        item.dataset.niveau = decalage;
                    }
                    if (delta) {
                        if (delta < decalage) {
                            item.dataset.niveau = decalage - delta;
                        }
                    }
                    // console.log(index, rang - index)
                });

            }

        },
        /**
         * On peut aussi scroller le div du top pour passer d'un slide à l'autre
         */
        activerScrollSlides() {
            this.slider().addEventListener('scroll', (e) => {
                attendre(() => {
                    this.trouverSlideCourant();
                })
            }, { passive: true });
            this.trouverSlideCourant();
        },
        /**
         * Utiliser l'intersectionObserver pour identifier quel est le slide courant
         */
        trouverSlideCourant() {
            let hash = document.location.hash.replace('#', '');
            let slide = this.top().querySelector(`[data-zone="slide"][data-id="${hash}"]`);
            if (!slide) return;
            this.setNumero(slide.dataset.numero)
            this.slideCourant = slide;
            emettreEvenement('top-changer-slide', { slide: this.slideCourant, top: this.top() }, this);
            this.top().dataset.io = true;
            // attendre('trouverSlideCourant', () => {
            //     let slides = this.top().querySelectorAll('[data-zone="slide"]');

            //     for (let slide of slides) {
            //         let sliderOffsetleft = document.querySelector('[data-zone="slider"]').offsetLeft;
            //         if (slide.offsetLeft >= sliderOffsetleft) {
            //             console.log('slide', slide.dataset.numero);
            //             return;
            //             this.setNumero(slide.dataset.numero)
            //             this.slideCourant = slide;
            //             emettreEvenement('top-changer-slide', { slide: this.slideCourant, top: this.top() }, this);
            //             this.top().dataset.io = true;
            //             return true;
            //         }
            //     }
            // });
            /* io = new IntersectionObserver((entries, observer) => {
                 console.log('entries', entries)
                 entries.find(entry => {
                     console.log('entry', entry)
                     if (entry.intersectionRatio > 0.1) {
                         // console.log(entry.target.dataset.numero)
                         this.setNumero(entry.target.dataset.numero)
                         this.slideCourant = entry.target;
                         emettreEvenement('top-changer-slide', { slide: this.slideCourant, top: this.top() }, this);
                         this.top().dataset.io = true;
                         return true;
                     }
                 });
             }, { root: this.top(), threshold: 1 });
             slides.forEach(slide => io.observe(slide))*/
        },
        slider() {
            let slider = this.top().querySelector('[data-zone="slider"] [data-zone="scroll"]');
            // console.log('slider', slider);
            return slider;
        },
        top() {
            let top = document.querySelector('[data-zone="top"]');
            sens = 'vertical';
            if (!top) {
                top = document.querySelector('[data-zone="topv2"]');
                sens = 'horizontal';
            }
            return top;
        },
        numeros() {
            return this.top().querySelector('[data-zone="numeros"]');
        },
        numerosScroll() {
            return this.numeros().querySelector('[data-zone="scroll"]');
        },
        start() {
            let top = this.top();
            if (top) {
                slides = top.querySelectorAll('[data-zone="slide"]');
                // this.activerScrollNumeros();
                // this.activerScrollSlides();
                this.activerNavigationClavier();
                this.disableAnchorScroll();
            }

        }
    }
})();