App.modules.clavier = (() => {
    let evenementsTouches = ['Escape'];
    let touchesBloquees = []
    /**
     * Ajouter des touches aux évenements
     * @param {array} touches 
     */
    function add(touches) {
        touches.forEach(touche => {
            evenementsTouches.push(touche);
        })
        evenementsTouches = [...new Set(evenementsTouches)];
        log(evenementsTouches);
    }
    /**
     * Bloquer l'action par defaut des touches passées en paramètre
     * @param {array} touches 
     */
    function prevent(touches) {
        // add(touches);
        touches.forEach(touche => {
            touchesBloquees.push(touche);
        })
        touchesBloquees = [...new Set(touchesBloquees)];
    }
    /**
     * Débloquer toutes les touches
     */
    function clearPrevent() {
        touchesBloquees = [];
    }
    return {
        start() {
            document.addEventListener('keydown', (e) => {
                let touche = e.key;
                if(e.ctrlKey) {
                    touche = 'Ctrl-'+touche;
                }
                let input = e.target.closest(`input, textarea`);
                if(input) {
                    if(!input.dataset?.autoriserClavier) {
                        return;
                    }    
                }    
                
                if (touchesBloquees.includes(touche)) {
                    e.preventDefault();
                    log('Touche bloquée : ' + touche)
                }    
                log('Touche:'+touche);
                if (evenementsTouches.includes(touche)) {
                    log(evenementsTouches);
                    patienter(() => {
                        log('Event émis : touche-' + touche)
                        emettreEvenement('key-' + touche, { touche, event: e }, this);
                    }, { delay: 100 });
                }
            });
        },
        prevent, clearPrevent, add
    }
})();