/**
 * Module associés au swipe
 */
App.modules.swipe = (() => {
    let touchstartX = 0;
    let touchstartY = 0;
    let touchendX = 0;
    let touchendY = 0;
    let tolerance = 30; // largeur minimale ne %age de l'ecran

    function handleGesture() {

        tolerance_width = window.innerWidth * tolerance / 100;
        let deplacement = Math.abs(touchstartX - touchendX);
        let seuil = deplacement > tolerance_width;
        console.log('handleGesture', deplacement, tolerance_width, seuil);
        if (touchendX < touchstartX) {
            if (seuil) {
                document.body.dataset.swipe = "left"
            }
        }

        if (touchendX > touchstartX) {
            if (seuil) {
                document.body.dataset.swipe = "right"
            }

        }

        attendre('clearSwipe', clearSwipe, 3000);

        // if (touchendY < touchstartY) {
        //     console.log('Swiped up');
        // }

        // if (touchendY > touchstartY) {
        //     console.log('Swiped down');
        // }

        // if (touchendY === touchstartY) {
        //     console.log('Tap');
        // }
    }

    function clearSwipe() {
        console.log('clearSwipe');
        delete document.body.dataset.swipe;
    }
    return {
        load() {
            return;
            
            console.log('swipe load');

            document.addEventListener('scroll-enought', clearSwipe, { passive: true });

            document.body.addEventListener('touchstart', (event) => {
                touchstartX = event.changedTouches[0].screenX;
                touchstartY = event.changedTouches[0].screenY;
            }, { passive: true, capture: false });

            document.body.addEventListener('touchend', (event) => {
                touchendX = event.changedTouches[0].screenX;
                touchendY = event.changedTouches[0].screenY;
                handleGesture();
            }, { passive: true, capture: false });

        },

    }
})();




