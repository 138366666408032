
App.modules.ads = (() => {
    let grandeImage;
    let petiteImage;
    let cible;
    let ouvrirExpandDelay;
    let capping;


    return {
        start() {
            if (document.location.hash.includes('expand')) {
                let capping = document.location.hash.includes('capping') ? 2 : false;

                App.modules.ads.activerExpand({
                    petiteImage: '/images/ads/expand-petit.jpg',
                    grandeImage: '/images/ads/expand-grand.jpg',
                    petiteImageMobile: '/images/ads/expand-petit-mobile.jpg',
                    grandeImageMobile: '/images/ads/expand-grand-mobile.jpg',
                    cible: 'https://www.graff.com/eu-fr/home/',
                    capping
                })
            }
        },
        activerExpand(params = {}) {
            grandeImage = params?.grandeImage || false;
            petiteImage = params?.petiteImage || false;
            grandeImageMobile = params?.grandeImageMobile || grandeImage;
            petiteImageMobile = params?.petiteImageMobile || petiteImage;

            cible = params?.cible || false;
            ouvrirExpandDelay = params?.ouvrirExpandDelay || 10;
            capping = params?.capping || false;

            document.querySelector('.userExpand')?.remove();
            let html = `
            <span class="spinner"><span class="spinner-icon"></span></span>
            <span class="petite desktop" style="background-image: url('${petiteImage}');"></span>
            <span class="grande desktop" style="background-image: url('${grandeImage}');"></span>
            <span class="petite mobile" style="background-image: url('${petiteImageMobile}');"></span>
            <span class="grande mobile" style="background-image: url('${grandeImageMobile}');"></span>
            `;
            let userExpand = document.createElement('a');
            userExpand.classList.add('userExpand');
            userExpand.innerHTML = html;
            userExpand.href = cible;
            userExpand.target = '_blank';
            document.body.prepend(userExpand);

            document.querySelector('html').dataset.expand = 'true';

            if (!capping || isTimeToDoTask('expand', capping)) {
                console.log('capping expand ok');
                setTimeout(() => {
                    App.modules.ads.ouvrirEtFermerExpand();
                }, 500);
            }
            document.addEventListener('scroll-start', App.modules.ads.fermerExpand);

            userExpand.addEventListener('click', (e) => {
                console.log(e.target, e.target.closest('.petite.mobile'));
                if (e.target.closest('.petite.mobile')) {
                    if (!App.modules.ads.expandOuvert()) {
                        e.preventDefault();
                        App.modules.ads.ouvrirExpand();
                    }
                }
            });
        },
        fermerExpand() {
            if (document.querySelector('html').dataset.expandOpen)
                delete document.querySelector('html').dataset.expandOpen;
        },
        ouvrirExpand() {
            document.querySelector('html').dataset.expandOpen = 'true';
        },
        expandOuvert() {
            return document.querySelector('html').dataset?.expandOpen;
        },
        ouvrirEtFermerExpand() {
            App.modules.ads.ouvrirExpand();
            setTimeout(() => {
                App.modules.ads.fermerExpand();
            }, ouvrirExpandDelay * 1000);
        },
        activerInterstitiel(params) {
            if (document.querySelector('#interstitiel')) return;

            const capping = params?.capping;
            const key = 'capping-' + md5(JSON.stringify(params))
            const image = params?.image;
            if (!image) return;
            const lien = params?.lien;
            if (!lien) return;
            const duree = params?.duree;

            if (capping) {
                const storedValue = localStorage.getItem(key);
                if (storedValue) {
                    const { date } = JSON.parse(storedValue);
                    const today = new Date().toISOString().split('T')[0];
                    if (date === today) {
                        return;
                    }
                }
                const data = { date: new Date().toISOString().split('T')[0] };
                localStorage.setItem(key, JSON.stringify(data));
            }

            const interstitiel = document.createElement('dialog');
            interstitiel.id = 'interstitiel';
            document.body.append(interstitiel);
            interstitiel.dataset.attendre=true
            interstitiel.innerHTML = `
                <button type="button"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 271.18 69.86"><path d="M218.46 52.15c0 5.08-1.51 7.53-6.21 7.53S206 57.23 206 52.15V17.7c0-5.08 1.51-7.53 6.21-7.53s6.21 2.45 6.21 7.53zM212.25 0C200 0 194.36 7.81 194.36 17.42v35c0 9.6 5.65 17.42 17.89 17.42s17.89-7.81 17.89-17.42v-35C230.13 7.81 224.48 0 212.25 0M119 11.11h20.9V.94h-32.6v68H119V38.78h15.6V28.62H119zm-50.07 41c0 5.08-1.51 7.53-6.21 7.53s-6.21-2.45-6.21-7.53V17.7c0-5.08 1.5-7.53 6.21-7.53s6.21 2.45 6.21 7.53zm11.68.28v-35C80.58 7.81 74.93 0 62.69 0S44.8 7.81 44.8 17.42v35c0 9.6 5.65 17.42 17.89 17.42S80.58 62 80.58 52.43m-44.58 0c0-18.45-23.72-24-23.72-35.58 0-4.23 2.07-6.68 5.84-6.68 2.64 0 5.74 1.69 6.21 7.53l11.07-1.22C34.36 6.87 28.71 0 19.2 0 7.72 0 1.13 6.12 1.13 16.94c0 19.49 23.72 23.06 23.72 36.53 0 4.05-2.73 6.21-6.78 6.21-3.77 0-6.5-3.39-7-10.26L0 51c.47 10.19 6.68 18.85 17.51 18.85S36 63.64 36 52.43M236.91.94v10.17h11.3v57.8h11.67v-57.8h11.3V.94zm-65.52 51.21c0 5.08-1.51 7.53-6.21 7.53S159 57.23 159 52.15V17.7c0-5.08 1.51-7.53 6.21-7.53s6.21 2.45 6.21 7.53zM165.18 0c-12.24 0-17.89 7.81-17.89 17.42v35c0 9.6 5.65 17.42 17.89 17.42s17.89-7.81 17.89-17.42v-35C183.06 7.81 177.42 0 165.18 0"/></svg><span><span>Accéder au site</span><i></i></span></button>
                <a href="${lien}" target="_blank"><img src="${image}"></a>
            `
            interstitiel.showModal();
            interstitiel.addEventListener('click', e => {
                const button = e.target.closest('button');
                if (!button) return;
                if (interstitiel.dataset.attendre) return;
                interstitiel.close();
                interstitiel.remove()
            })
            if (duree) {
                interstitiel.dataset.attendre = true;
                let compteur = duree;
                // interstitiel.querySelector('button>span>span').innerHTML = compteur;
                const loader = interstitiel.querySelector('button>span>i');
                loader.style = "width:0%"
                const st = setInterval(() => {
                    const style = "width:calc(100% - (("+compteur+" - 1) * 100% / "+duree+"))";
                    console.log({style})
                    loader.setAttribute('style',style)
                    compteur--
                    // interstitiel.querySelector('button>span>span').innerHTML = --compteur
                    if (compteur == 0) {
                        // interstitiel.querySelector('button>span>span').innerHTML = 'Accéder au site';
                        clearInterval(st);
                        delete interstitiel.dataset.attendre;
                        setTimeout(() => loader.remove(), 500)
                    }
                }, 1000)
            }
            return true;
        },
        // activerInterstitiel(params) {
        //     let div = document.querySelector('.interstitiel');
        //     if (div) return;
        //     div = document.createElement('div')
        //     div.setAttribute('class', 'interstitiel')
        //     div.innerHTML = `<a target="_blank" rel="nofollow" class="crea-interstitiel"></a>`
        //     document.body.append(div)
        //     console.log({ div })

        // },
        activerHabillage(params) {
            let html = document.querySelector('html');
            html.dataset.arche = 'true';

            // let une = document.querySelectorAll('[data-zone="une"] > .article[data-mode="auto"]')[1] || false;
            // if (une) {
            //     // une.dataset.mode = "normal";
            // }
            // console.log(params);
            let style = [];

            let body_selector = 'body:not(:is([data-breakpoint="mini"], [data-breakpoint="petit"], [data-breakpoint="moyen"]))'
            style.push(`.habillage {height : ${params?.hauteur}}`);
            if (params?.sticky) {
                style.push(`.crea-habillage {background-color : ${params?.fond};}`);
            } else {
                style.push(`.crea-habillage {background-color : ${params?.fond}; background-image : url(${params?.image})}`);
            }
            if (params.zoom) {
                let zoom = params.zoom / 100;
                if (zoom) {
                    style.push(`.crea-habillage {scale : ${zoom}}`);
                }
            }
            if (params.fixe) {
                style.push(`.crea-habillage {position:fixed !important}`);
            }
            let tag = this.habillageStyleTag()
            tag.innerHTML = style.map(item => body_selector + ' ' + item).join('\n');
            const crea = document.querySelector('.crea-habillage');
            crea.setAttribute('href', params?.lien);
            if (params?.sticky) {
                crea.classList.add('sticky');
                crea.innerHTML = `<img src="${params?.image}">`;
            }
            if (params.pixel) {
                let div = document.createElement('div');
                div.innerHTML = params.pixel;
                div.id = 'pixel-habillage';
                document.body.append(div);
            }
            const headerHeight = document.querySelector('.header').offsetHeight;
            const habillage = document.querySelector('.crea-habillage');
            habillage.style.top = headerHeight + 'px';
            window.addEventListener('scroll', function () {
                habillage.style.top = (window.scrollY < headerHeight) ? (headerHeight - window.scrollY + 'px') : 0;
            });

        },
        habillageStyleTag() {
            let tag = document.querySelector('head style#habillage');
            if (!tag) {
                tag = document.createElement('style');
                tag.id = 'habillage'
                document.querySelector('head').appendChild(tag)
            }
            return tag;
        }
    }
})();



/*



            console.log(params);
            let html = document.querySelector('html');
            html.dataset.arche = 'true';
            html.style.background = params?.fond;
            let style = [
                `background: url(${params?.image}) no-repeat top center ${params?.fond}`,
                `background-size: ${params.zoom}% auto`,
                `padding-top: ${params?.hauteur}`,
            ];
            if (params?.fixed) {
                let h = document.querySelector('header.header').getBoundingClientRect().height
                style.push(`background-attachment: fixed`);
                style.push(`background-position: center ${h}px`);
            }
            document.querySelector('[data-arche="true"] body > #main').setAttribute('style', style.join(';'));
            document.addEventListener('click', e => {
                if (e.target.id == 'main') {
                    window.open(params.lien, '_blank');
                }
            })
*/