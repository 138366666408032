App.modules.site = (() => {
    return {
        start() {
            this.activerOnglets();
        },
        activerOnglets() {
            let onglets = document.querySelector('.onglets-fiche, .onglets-page');
            if (onglets) {
                let bouton = document.querySelector('[data-action="onglets-fiche"], [data-action="onglets-page"]');
                if (bouton) {
                    bouton.addEventListener('click', () => {
                        if (!onglets.dataset.ouvert) {
                            onglets.dataset.ouvert = true;
                        } else {
                            delete onglets.dataset.ouvert;
                        }
                    });
                }
                if (!onglets.querySelector(`.active`)) {
                    let page = document.location.href.split('/')[3].split('#')[0];
                    // let page = document.location.href.split('/')[5];
                    // selectionner l'onglet correspondant à la page
                    if (page) {
                        onglets.querySelector(`a[href*="${page}"]`)?.closest('li').classList.add('active')
                    } else {
                        onglets.querySelector(`li`)?.classList.add('active')
                    }
                }

            }
        }
    }
})();
