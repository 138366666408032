App.modules.responsive = (() => {

    return {
        isMobile() {
            const breakpoint = document.body.dataset.breakpoint;
            if (breakpoint == 'mini') return true;
            if (breakpoint == 'petit') return true;
            return false;
        },
        currentEtape(slug) {
            return document.body.dataset.etape == slug;
        },
        setBreakpoints() {
            document.documentElement.style.setProperty('--scrollbar-width', (window.innerWidth - document.documentElement.clientWidth) + "px")

            let breakpoint = getComputedStyle(document.body, ':after').content.replaceAll('"', '');
            document.body.dataset.breakpoint = breakpoint;
            let etape = getComputedStyle(document.body, ':before').content.replaceAll('"', '');
            document.body.dataset.etape = etape;
            let grille = getComputedStyle(document.querySelector('#main'), ':before').content.replaceAll('"', '');
            // console.log(grille)
            document.body.dataset.grille = grille;
        },
        start() {
            ['load', 'resize'].forEach(event => window.addEventListener(event, this.setBreakpoints));
            this.setBreakpoints();
        }
    }
})();

// App.modules.responsive.setBreakpoints();