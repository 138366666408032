App.modules.commentaires = (() => {
    let commentaires;

    return {
        activerCommentaires() {
            document.querySelectorAll('[data-action="ouvrir-commentaires"]').forEach(button => button.addEventListener('click', () => {
                App.modules.commentaires.ouvrirCommentaires()
            }));

            document.querySelectorAll('[data-action="fermer-commentaires"]').forEach(button => button.addEventListener('click', () => {
                App.modules.commentaires.fermerCommentaires(0);

            }))
            document.addEventListener('key-Escape', () => {
                App.modules.commentaires.fermerCommentaires();
            });
            //window.addEventListener('hashchange', this.formatCommentairesFromHash);
            this.formatCommentairesFromHash();


        },
        formatCommentairesFromHash() {
            let hash = document.location.hash.replace('#', '');
            if (hash == 'commentaires-droite') {
                App.modules.commentaires.ouvrirCommentaires();
            } else {
                App.modules.commentaires.fermerCommentaires(0);
            }
        },
        ouvrirCommentaires() {
            document.body.dataset.commentairesReady = true;
            setTimeout(() => {
                document.body.dataset.commentairesStart = true;
                setTimeout(() => {
                    document.body.dataset.commentairesOpen = true;
                }, 100);
            }, 100);

        },
        fermerCommentaires(delay = 100) {
            delete document.body.dataset.commentairesOpen;
            setTimeout(() => {
                delete document.body.dataset.commentairesReady;
                setTimeout(() => {
                    delete document.body.dataset.commentairesStart;
                    if(delay) {
                        document.location.hash = '';
                    }
                }, delay);
            }, delay);
        },
        start() {

            commentaires = document.querySelector('[data-zone="commentaires"]');
            if (commentaires) {
                this.activerCommentaires();
            }
        }
    }
})();