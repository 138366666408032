App.modules.dataSelect = (() => {
    let DataSelects;
    return {
        activerDataSelects() {

            DataSelects.forEach(DataSelect => {
                this.setSelectedText(DataSelect);
                document.addEventListener('key-Escape', () => {
                    this.closeDrawer(DataSelect)
                });
                document.addEventListener('click', (e) => {
                    if (!e.target.closest('[data-select]')) {
                        this.closeDrawer();
                    }
                });

                ['keydown', 'change'].forEach(evenement => {
                    DataSelect.querySelector('[data-select-search]').addEventListener(evenement, (e) => {

                        if (e?.key == 'Enter') {
                            e.preventDefault();
                            this.selectCurseur(DataSelect);
                        } else if (e?.key == 'ArrowDown') {
                            this.setCurseur('down', DataSelect)
                        } else if (e?.key == 'ArrowUp') {
                            this.setCurseur('up', DataSelect)
                        } else {
                            this.dataSelectSearch(DataSelect)
                        }

                    })
                })

                DataSelect.querySelector('[data-select-button] button').addEventListener('click', (e) => {
                    let DataSelect = e.target.closest('[data-select]');
                    if (DataSelect.dataset.drawerIs == 'open') {
                        this.closeDrawer(DataSelect)
                    } else {
                        this.openDrawer(DataSelect);
                        this.dataSelectSearch(DataSelect, true);
                    }
                })
            })

        },

        selectCurseur(DataSelect) {
            const element = DataSelect.querySelector('[data-select-curseur]');
            // //console.log('selectCurseur', element)
            if (element) {
                this.selectElement(DataSelect, element)
            }
        },
        setCurseur(sens, DataSelect) {
            let curseur = DataSelect.querySelector('[data-select-curseur]');
            if (!curseur) {
                curseur = DataSelect.querySelector('[data-select-results] button');
            } else {
                // //console.log(curseur);
                let newCurseur;
                if (sens == 'down') {
                    // //console.log(curseur.nextElementSibling)
                    newCurseur = curseur.nextElementSibling;
                } else {
                    // //console.log(curseur.previousElementSibling)
                    newCurseur = curseur.previousElementSibling;
                }
                if (newCurseur) {
                    delete curseur.dataset.selectCurseur;
                    curseur = newCurseur;
                }
            }
            if (curseur) {
                curseur.dataset.selectCurseur = true;
            }
            // //console.log(sens, curseur)
        },
        /**
         * Retourne le texte de la valeur sélectionnée s'il y en a une
         * @param {string} text Le texte de la valeur selectionnée 
         * @returns 
         */
        getDataText(DataSelect) {
            return DataSelect.querySelector('[data-select-text]')?.value;
        },
        setSelectedText(DataSelect) {
            DataSelect.querySelector('[data-select-button] select').innerHTML = `<option>${this.getDataText(DataSelect)}</option>`;
        },
        /**
         * Fermer le tiroir de résultats s'il est ouvert. 
         * Au passage : vider le champs de recherche, et afficher le texte sélectionné dans le "fake select"
         * @param {element} DataSelect L'element parent (si false : tous les tiroirs ouverts seront fermés)
         */
        closeDrawer(DataSelect = false) {
            //console.log('closeDrawer', DataSelect)
            if (DataSelect) {
                if (DataSelect.dataset.drawerIs) {
                    DataSelect.querySelector('[data-select-button] select option').innerHTML = this.getDataText(DataSelect);
                    DataSelect.querySelector('[data-select-search]').value = '';
                    attendre(() => {
                        delete DataSelect.dataset.drawerIs
                        delete DataSelect.dataset.wait
                    });
                }
            } else {
                document.querySelectorAll('[data-drawer-is="open"]').forEach((element) => {
                    this.closeDrawer(element);
                });
            }
        },
        openDrawer(DataSelect) {
            //console.log(DataSelect)
            DataSelect.dataset.wait = true;
            DataSelect.dataset.drawerIs = 'open'
            DataSelect.querySelector('[data-select-search]').focus();
        },

        activerResults(DataSelect) {
            DataSelect.querySelectorAll('[data-select-results] button').forEach(button => button.addEventListener('click', (e) => {
                this.selectElement(DataSelect, e.target)
            }))
        },
        selectElement(DataSelect, button) {
            const value = button.dataset.value;
            const text = button.innerHTML;
            // //console.log('selectElement', value, text)
            DataSelect.querySelector('[data-select-text]').value = text;
            DataSelect.querySelector('[data-select-value]').value = value;
            this.setSelectedText(DataSelect);
            this.closeDrawer(DataSelect);
        },
        getTemplate(DataSelect) {
            //const template = getComment(DataSelect.querySelector('[data-select-template]'));
            let template;
            if (document.querySelector('[data-select-template]')) {
                template = document.querySelector('[data-select-template]').innerHTML.trim();

            }
            if (!template) {
                template = '<button type="button" ${selected} data-value="${result[key]}">${result[field]}</button>';

            }
            return template;
        },
        dataSelectSearch(DataSelect, resetSearch = false) {
            const field = DataSelect.dataset.selectField;
            const key = DataSelect.dataset.selectKey;
            const input = DataSelect.querySelector('[data-select-search]')

            const results = DataSelect.querySelector('[data-select-results]')

            const template = this.getTemplate(DataSelect);
            const value = DataSelect.querySelector('[data-select-value]').value;
            const returnData = DataSelect.dataset.selectReturnData;
            const returnTotal = DataSelect.dataset.selectReturnTotal;
            let source = DataSelect.dataset.selectSource;
            console.log(returnData, returnTotal)
            //console.log({ field, key, source, input, results, template, value });
            let urlSource;
            if (resetSearch) {
                input.value = '';
            }
            patienter(() => {
                const search = input.value;
                if (DataSelect.dataset.search != search) {
                    DataSelect.dataset.search = search;
                    results.innerHTML = ''
                    if (search) {
                        urlSource = eval('`' + source + '`');
                        // //console.log(urlSource);
                        DataSelect.dataset.selectLoading = true;
                        $.post(urlSource).then(response => {
                            let html = [];
                            let total;
                            if (returnTotal) {
                                total = response[returnTotal];
                            }
                            let data;
                            if(returnData) {
                                 data = response[returnData];
                            } else {
                                data = response;
                            }
                            data.forEach((result) => {
                                const selected = result[key] == value ? 'data-selected="true"' : '';
                                let element = eval('`' + template + '`')
                                if (element) {
                                    html.push(element);
                                }
                            })
                            results.innerHTML = html.join('')
                            this.activerResults(DataSelect);
                            delete DataSelect.dataset.selectLoading;
                        });
                    }
                }
            })
        },
        start() {
            DataSelects = document.querySelectorAll('[data-select]');
            if (DataSelects.length) {
                this.activerDataSelects();
            }
        }
    }
})();