App.modules.matchs = (() => {
	let matchs;

	return {
		start() {
			matchs = {
				container: document.querySelector('[data-zone="fiche-match"]'),
			};

			if (matchs.container) {
				this.matchStatistiquesBar();

				if (compositionContainer = document.querySelector('.composition-container')) {
					let liens = compositionContainer.querySelectorAll('.logos-clubs a');

					liens.forEach(lien => {
						lien.addEventListener('click', e => {
							e.preventDefault();

							let selectionne = document.querySelector('.selectionne');
							selectionne.classList.remove('selectionne');
							lien.classList.add('selectionne');
							compositionContainer.dataset.selectionne = lien.dataset.composition;
						});

					});
				}

			}
		},
		matchStatistiquesBar() {
			let barContainer = {
				'possession': '.possession',
				'passesreussies': '.passes-reussies'
			};

			Object.keys(barContainer).forEach(key => {
				let statDomicile = '';
				let statExterieur = '';

				if (container = matchs.container.querySelector('.statistiques ' + barContainer[key])) {
					let domicileContainer = container.querySelector('.domicile span');
					let exterieurContainer = container.querySelector('.exterieur span');

					let barreDomicile = domicileContainer.nextElementSibling;
					let barreExterieur = exterieurContainer.previousElementSibling;

					statDomicile = domicileContainer.dataset[key];
					statExterieur = exterieurContainer.dataset[key];

					barreDomicile.style.width = statDomicile + '%';
					barreExterieur.style.width = statExterieur + '%';
				}
			});
		},
	}

})();