
App.modules.article = (() => {
    
    const activerBarreArticle = () => {
        let barre = document.querySelector('.barre-article [data-action="share"]');
        if(barre) {
            barre.addEventListener('click',(e) => {
                e.target.closest('[data-zone]').dataset.opened=true;
            })
        }
    }

    return {
        start() {
            activerBarreArticle();
        }
    }
})();