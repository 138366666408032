App.modules.recherche = (() => {
    let recherche, input, resultatsRecherche, currentPage;
    return {
        afficherResultats(resultats) {
            let html = [];
            resultats.articles.forEach(article => {
                let template = getTemplate('resultat-recherche', article);
                html.push(template);
            });
            if(html.length) {
                html = html.join('');
            } else {
                html = '<center><i>Aucun contenu ne correspond à votre recherche</i></center>';
            }
            resultatsRecherche.articles.innerHTML = html

            html = [];
            resultats.fiches.equipes.forEach(equipe => {
                let template = getTemplate('resultat-recherche-equipe', equipe);
                html.push(template);
            });
            resultatsRecherche.fiches.equipes.innerHTML = html.join('');

            html = [];
            resultats.fiches.joueurs.forEach(joueur => {
                let template = getTemplate('resultat-recherche-joueur', joueur);
                console.log(resultatsRecherche)
                html.push(template);
            });
            resultatsRecherche.fiches.joueurs.innerHTML = html.join('');
        },
        activerRecherche() {
            document.addEventListener('key-Escape', this.fermerRecherche);

            document.addEventListener('click', e => {
                if (e.target.closest('[data-action="fermer-recherche"]')) {
                    this.fermerRecherche()
                }
            });
            ['keyup', 'change'].forEach(evenement => input.addEventListener(evenement, (e) => {
                patienter(() => {
                    simulerPageVue('/search?s=' + encodeURIComponent(input.value), 'Rechercher "input.value"');
                    this.rechercher();
                })
            }));

            input.addEventListener('focus', this.ouvrirRecherche);
            recherche.addEventListener('submit', e => {
                e.preventDefault();
                this.rechercher();
            })


        },
        fermerRecherche() {
            fromFixedToRelative(recherche);
            setTimeout(() => {
                if(currentPage) {
                    simulerPageVue(currentPage.url, currentPage.titre);
                }
                resultatsRecherche.articles.innerHTML = '';
                resultatsRecherche.fiches.equipes.innerHTML = '';
                resultatsRecherche.fiches.joueurs.innerHTML = '';
            }, 500)
        },
        ouvrirRecherche() {
            if (!currentPage) {
                currentPage = { url: document.location.href, titre: document.title };
                fromRelativeToFixed(recherche);
                simulerPageVue('/search', 'Rechercher');
            }
        },
        rechercher() {
            this.ouvrirRecherche();
            let termes = recherche.querySelector('[type="text"]').value;
            resultatsRecherche.articles.innerHTML = loading();
            emettreEvenement('menu-slide-recherche', { termes }, this);
        },
        start() {
            recherche = document.querySelector('[data-zone="menu-slide"] form[data-action="formulaire-recherche"]');
            if (recherche) {
                resultatsRecherche = {
                    articles: recherche.querySelector('[data-zone="resultats"] [data-resultats="articles"]'),
                    fiches: {
                        equipes: recherche.querySelector('[data-zone="resultats"] [data-type="equipes"] div'),
                        joueurs: recherche.querySelector('[data-zone="resultats"] [data-type="joueurs"] div')
                    }
                };
                input = recherche.querySelector('input[type="text"]');
 // recherche visuelle désactivée

/*                this.activerRecherche();
                if (document.location.hash.replace('#', '') == 'rechercher') {
                    App.modules.menu.ouvrirMenuSlide();
                    setTimeout(() => {
                        this.ouvrirRecherche();
                    }, 100)
                }*/
            }
        }
    }
})();