App.modules.darkMode = (() => {
    let html, scheme;
    return {
        detecter() {
            if (!window.matchMedia) return;
            if (!html.dataset.userColorScheme || html.dataset.userColorScheme == 'false') {
                window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
                    if (e.matches) {
                        html.dataset.userColorScheme = 'dark';
                    } else {
                        delete html.dataset.userColorScheme;
                    }
                });
                if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    html.dataset.userColorScheme = 'dark';
                }
            }
        },
        activer() {
            let scheme;
            document.querySelectorAll('[data-action="dark-mode-toggle"]').forEach(element => {
                element.addEventListener('click', (e) => {

                    if (html.dataset.userColorScheme == 'dark') {
                        scheme = 'light';
                    } else {
                        scheme = 'dark';
                    }
                    this.setScheme(scheme)
                });
            });

            if (scheme = localStorage.getItem('color-scheme')) {
                html.dataset.userColorScheme = scheme
            } else {
                //this.detecter();
            }
        },
        start() {
            html = document.querySelector('html');
            this.activer()
        },
        getScheme() {
            return html.dataset.userColorScheme == 'dark' ? 'dark' : 'light';
        },
        toggleScheme() {
            let scheme = 'light';
            if (this.getScheme() != 'dark') {
                scheme = 'dark'
            }
            this.setScheme(scheme);
        },
        setScheme(scheme) {
            if (html.dataset.userColorScheme != scheme) {
                emettreEvenement('dark-mode-toggle', { scheme }, this);
            }
            localStorage.setItem('color-scheme', scheme);
            html.dataset.userColorScheme = scheme
        }
    }
})();
