App.modules.header = (() => {


    let base, defilant, delai, stopper, qte, elements, playPause;
    let limite = 50; // nombre de boucles avant que la liste ne soit reinitialisée

    function reduceOnScroll() {
        document.removeEventListener('scroll-end', reduceOnScroll);
        document.body.dataset.header = 'reduced';
        saveHeaderHeigh();
        // document.querySelector('header.header').dataset.scrollFixed = true;
        // App.modules.scroll.activerScrollFixed();
        // document.removeEventListener('scroll', reduceOnScroll)
    }
    function saveHeaderHeigh() {
        let oh = document.querySelector('body:not([data-sens="down"][data-scrolled])>header.header__sticky')?.offsetHeight || 0;
        document.body.style.setProperty("--hauteur-header-computed", oh + 'px')
    }

    return {
        stopper,
        activerDefilant() {
            qte = defilant.querySelectorAll('li').length;

            if (playPause = base.querySelector('[data-action="play-pause"]')) {
                playPause.addEventListener('click', () => {
                    if (base.dataset.pause) {
                        delete base.dataset.pause;
                    } else {
                        base.dataset.pause = true;
                    }
                });
            }
            defilant.addEventListener('mouseover', () => {
                defilant.dataset.mouseover = "true"
            })
            defilant.addEventListener('mouseout', () => {
                patienter(() => {
                    delete defilant.dataset.mouseover;
                });
            })

            setInterval(() => {
                // si la souris est au dessus du bandeau défilant, on stoppe pour permettre la lecture
                if (defilant.dataset.mouseover) return;

                // si le defilant est en pause, on ne fait rien
                if (base.dataset.pause) return;

                // si la page est scrollée vers le bas, on stoppe car le bandeau défilant n'est plus visible
                if (window.scrollY) return;

                // si le defilmement est stoppé manuellmement (debug)
                if (this.stopper) return;

                // si la page est en mode mobile, le defilant n'est pas visible, donc pas besoin de le faire scroller
                if (document.body.dataset.etape == 'moins-que-moyen') return;

                // // on clone la série d'elements pour avoir l'effet d'un scroll inifi
                // elements = defilant.querySelectorAll('li');
                // if(elements.length == qte) {
                //     elements.forEach(element => {
                //         defilant.appendChild(element.cloneNode(true));
                //     });
                // }

                let item = defilant.querySelector('li[data-current="true"]');
                if (!item) {
                    item = defilant.querySelector('li')
                }

                if (!item) return;

                // à chaque itration, le noeud courant est cloné et ajouté à la fin de la liste pour simuler un déroulement infini. 
                // au bout de ${limite} boucles, on repart sur le nombre d'element inital (pour eviter que le nb d'elements ne gonfle pas de maniere infinie)
                let clone = item.cloneNode(true);
                delete clone.dataset.current;
                defilant.appendChild(clone);
                if (defilant.querySelectorAll('li').length > qte * limite) {
                    defilant.querySelectorAll('li').forEach((element, i) => {
                        if (i > qte) {
                            element.remove();
                        }
                    });
                }

                let index = Array.prototype.indexOf.call(defilant.children, item);


                // if(index > qte) {
                //     let elementsARetirer = Array.prototype.slice.call(defilant.querySelectorAll('li'),0,qte)
                //     elementsARetirer.forEach(element => {
                //         element.remove();
                //     })
                // }

                let next = item.nextElementSibling;
                if (!next) {
                    next = defilant.querySelector('li')
                }
                next.dataset.current = "true";
                delete item.dataset.current;
                next.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

            }, delai * 1000);
        },
        start() {
            if (!document.querySelector('body>header')) return;
            delai = 3;
            if (base = document.querySelector('[data-zone="defilant"]')) {
                if (defilant = base.querySelector('ul')) {
                    // this.activerDefilant()
                }
            }

            document.addEventListener('scroll-end', reduceOnScroll, { passive: true });
        }
    }

})();




/*
    if (defilant) {
        defilant.addEventListener('wheel', (e) => {
            if (e.deltaY != 0) {
                e.preventDefault();
                let delta = ((e.deltaY || -e.wheelDelta || e.detail) >> 10) || 1;
                delta = delta * (-300);
                defilant.scrollLeft -= delta;

            }
        })
        defilant.addEventListener('mouseover', () => {
            defilant.dataset.mouseover = "true"
        })
        defilant.addEventListener('mouseout', () => {
            delete defilant.dataset.mouseover;
        })
        clearInterval(window.si_defilant);
        setInterval(() => {
            // si la souris est au dessus du bandeau défilant, on stoppe pour permettre la lecture
            if (defilant.dataset.mouseover) return;

            // si la page est scrollée vers le bas, on stoppe car le bandeau défilant n'est plus visible
            if (window.scrollY) return;

            // si la page est en mode mobile, le defilant n'est pas visible, donc pas besoin de le faire scroller
            if (document.body.dataset.etape == 'moins-que-grand') return;

            let item = defilant.querySelector('li[data-current="true"]');
            if (!item) {
                item = defilant.querySelector('li')
            }

            let next = item.nextElementSibling;
            if (!next) {
                next = defilant.querySelector('li')
            }
            next.dataset.current = "true";
            delete item.dataset.current;
            next.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });

        }, 1000);
    }

*/